import { cleanNullValueObjectFirstLevel } from './utils.js'
import { times } from "@shein/common-function";

const getCurrentTab = (query = {}, tabList = []) => {
  const tab = tabList.find(tab => tab.order - query.active_tab === 0)
  return tab || {}
}

// 商品接口入参
const getGoodsParams = requestInfo => {
  const query = requestInfo.query || {}
  const tab = getCurrentTab(requestInfo.query, requestInfo.tabList)
  const min_discount_rate = times(tab.minRate || 0.01, 100)
  const max_discount_rate = times(tab.maxRate || 0.99, 100)
  let params = {
    // 列表场景,传值：search 搜索；real 真实；select 选品
    scene: query.scene,
    goods_id: query.adp ,
    goods_type: tab.type || query.goods_type,
    limit: query.limit || 10,
    page: query.page || 1,
    min_discount_rate,
    max_discount_rate,
    // cat_id/select_id/word
    cat_id: query.cat_id,
    select_id: query.select_id,
    word: query.word,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)
  return params
}

// tab接口入参
const getTabsParams = requestInfo => {
  const query = requestInfo.query || {}
  const tab = getCurrentTab(requestInfo.query, requestInfo.tabList)
  let params = {
    // 列表场景,传值：search 搜索；real 真实；select 选品
    scene: query.scene,
    entry_click_discount_tab: query.entry_click_discount_tab,
    goods_id: query.adp,
    has_flash_buy_goods: query.has_flash_buy_goods === '1' ? 1 : 0,
    goods_type: tab.type || query.goods_type,
    // cat_id/select_id/word
    cat_id: query.cat_id,
    select_id: query.select_id,
    word: query.word,
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)
  return params
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @returns
 */
export const getDiscountGenerateBffParams = (requestInfo) => {
  return {
    goodsParams: getGoodsParams(requestInfo),
    tabsParams: getTabsParams(requestInfo),
  }
}
