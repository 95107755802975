import { getCommonParams, getVuexInfo, getListMainParams  } from './common.js'
import { getCurrentToRoute } from '../utils'
import { cleanNullValueObjectFirstLevel, getGoodsAdp } from './utils.js'

const getGoodsParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo }, toRoute)

  const {
    quickship,
    filter,
    catId,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    filterBrandIds,
    filterMallCode
  } = getCommonParams(query)

  let params = {
    ...mainParams,
    quickship,
    filter,
    filterBrandIds,
    filter_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: urlMallCode || filterMallCode,
    sort: query.sort || '',
    styleType: query.styleType || '',
    adp: getGoodsAdp(query),
    _type: 'selling',

    // 以下为点刷重要参数
    page: query.page || 1,
    limit: query.limit || 10,
  }


  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 筛选相关
const getFilterParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  const {
    quickship,
    filter,
    filterTagIds,
    maxPrice,
    minPrice,
    tagIds,
    urlMallCode,
    catId,
    chooseIds,
    filterBrandIds,
    filterMallCode
  } = getCommonParams(query, searchKeywords)

  let params = {
    ...mainParams,
    quickship,
    filter,
    filter_cat_id: catId,
    filterBrandIds,
    choosed_ids: chooseIds,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    tag_ids: tagIds,
    mall_code_list: urlMallCode || filterMallCode,
    page_name: catInfo.pageName,
    styleType: query.styleType || '',
    showAllCategory: query.showAllCategory || '',
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    _type: 'selling'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}


// 标签云相关
const getCloudTagParams = (requestInfo) => {
  const toRoute = getCurrentToRoute() || {}
  const query = requestInfo.query || {}
  const { catInfo, searchKeywords } = getVuexInfo(toRoute)
  const { mainParams } = getListMainParams(requestInfo, { catInfo, searchKeywords }, toRoute)

  const {
    catId,
    filter,
    filterTagIds,
    maxPrice,
    minPrice,
    urlMallCode,
    filterMallCode
  } = getCommonParams(query, searchKeywords)


  let params = {
    ...mainParams,
    filter,
    filter_cat_id: catId,
    filter_tag_ids: filterTagIds,
    max_price: maxPrice,
    min_price: minPrice,
    choosed_mall_code: filterMallCode,
    mall_code_list: urlMallCode,
    styleType: query.styleType || '',
    _type: 'selling'
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns
 */
export const getSellingGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    filterParams: getFilterParams(requestInfo),
    cloudTagParams: getCloudTagParams(requestInfo),
  }
}
