/* eslint-disable require-atomic-updates */

import { defaultRequestInfo, setCurrentToRoute, PointerOverGoodsRequest, generateRouterParams, generateRealSelectBffFilterInfo, preloadPicTopNav } from './utils'
import { PRODUCT_LIST_ROUTE_NAME, ROUTE_REGEX, PRODUCT_LIST_REQUEST_URL_MAP } from './constant.js'
import generateParams from './generateParams'
import {
  getSearchGenerateBffParams, getRealGenerateBffParams, getSelectGenerateBffParams,
  getPickedGenerateBffParams, getFeedbackRecGenerateBffParams, getDiscountGenerateBffParams,
  getDailyNewGenerateBffParams, getSellingGenerateBffParams, getSheinPicksGenerateBffParams,
  getRealHalfGenerateBffParams,
} from './bffParamsHandle/index.js'
import { markPoint } from 'public/src/services/mark/index.js'
import { isSwitchBffApiVersion } from 'public/src/pages/common/bff-new/index'
import { abortionInstance, apiCache, clearNodeApiCache } from './api/nodeApi.js'
import {
  dailyNewFilterApiCache,
  dailyNewFilterAbortionInstance,
  dailyNewCloudTagApiCache,
  dailyNewCloudTagAbortionInstance,
  cccFeedbackAbortionInstance,
  cccFeedbackApiCache,
  goodsAbortionInstance,
  goodsApiCache,
  hypernymGoodsAbortionInstance,
  searchFilterAbortionInstance,
  searchFilterApiCache,
  selectionAndEntityFilterAbortionInstance,
  selectionAndEntityFilterApiCache,
  selectionAndEntityPicNavAbortionInstance,
  selectionAndEntityPicNavApiCache,
  selectionAndEntityCloudTagAbortionInstance,
  selectionAndEntityCloudTagApiCache,
  categoryDayTagApiCache,
  showRecentDaysTagAbortionInstance,
  showRecentDaysTagApiCache,
  cccCommonComponentApiCache,
  pickedFilterApiCache,
  pickedCloudTagApiCache,
  pickedFilterAbortionInstance,
  pickedCloudTagAbortionInstance,
  sheinPicksNavTabApiCache,
  sheinPicksNavTabAbortionInstance,
  feedbackRecFilterApiCache,
  feedbackRecCloudTagApiCache,
  feedbackRecFilterAbortionInstance,
  feedbackRecCloudTagAbortionInstance,
  searchBannerCardApiCache,
  searchBannerCardAbortionInstance,
  clearGoodsApiCache,
  clearHypernymGoodsApiCache,
  clearSearchFilterApiCache,
  clearSearchBannerCardApiCache,
  clearSelectionAndEntityFilterApiCache,
  clearSelectionAndEntityCloudTagApiCache,
  clearSheinPicksNavTabApiCache,
  discountPageInfoApiCache,
} from './api/bffApi.js'
import { promiseAsyncAuto } from './asyncAuto.js'
import { userTracekPreCheckEnd } from 'public/src/pages/common/userActionTracker/index.js'

const { DISCOUNT } = PRODUCT_LIST_REQUEST_URL_MAP

function clearApiCache() {
  apiCache.clear()
}

/**
 * 获取商品列表数据的接口调用
 * @customRequestQueue 自定义队列, 可以在指定的时机调用，例如在获取筛选数据之后就调用
 * */
const requestProductListApi = async ({ requestInfo, newSrcTabPageId, toRoute, useNodeApi, customRequestQueue = {} }) => {
  if (typeof window === 'undefined') return

  // const requestStrategy = requestInfo.query.isClickRefresh ? '点刷类型' : '分页类型'

  // console.log(`%c 当前请求类型为: ${requestStrategy} 其中 page: ${requestInfo.query.page} limit: ${requestInfo.query.limit} `, 'background: #222; color: #bada55')

  const nodeRequest = getNodeRequest(requestInfo, newSrcTabPageId, toRoute)
  const bffRequestQueue = await getBffRequestQueue(requestInfo, toRoute, newSrcTabPageId)
  const isBff = !useNodeApi && Object.keys(bffRequestQueue).length
  const isUserBehavior = toRoute?.path?.match(ROUTE_REGEX.pickedProducts)
  const isPicked = toRoute?.path?.match(ROUTE_REGEX.picked)
  const useBffSearchBannerCard = isSwitchBffApiVersion(['search_banner_card_v1'])?.search_banner_card_v1 ? 1 : undefined
  try {
    if (isBff) {
      const data = await promiseAsyncAuto({
        nodeRes: nodeRequest({
          useBffProducts: 1,
          useBffFilters: 1,
          useBffSearchBannerCard,
          withoutAbort: requestInfo.query?.withoutAbort,
          toRouteForward: toRoute
        }),
        ...bffRequestQueue,
        bffFilterInfo: ['nodeRes', 'filterData', (result, cb) => {
          if (!result.filterData) return cb(null, {})
          // filter相关数据都在这里处理。
          preloadPicTopNav({ nodeRes: result.nodeRes, toRoute, bffFilterInfo: result.filterData, isBff: true })
          cb(null, result.filterData)
        }],
        ...customRequestQueue,
      })
      const bffGoodsResult = data.goods || {}
      const bffCccFeedbackResult = data.cccFeedbackData || {}
      const cccCommonComponentResult = data.cccCommonComponentApiData || {}
      const bffSearchBannerCardResult = data.searchBannerCard || {}
      const bffTabs = data.tabs || {}

      // 需要把筛选链路请求的数据暴露出去(根据exposeKeys按需提取)，给后面链路使用
      let needExposeFilterInfo = {}
      const filterInfoWithNodeRes = data._filterRenderHandle?.filterInfoWithNodeRes || {}
      const exposeKeys = data._filterRenderHandle?.exposeKeys || []
      if (Object.keys(filterInfoWithNodeRes).length && exposeKeys.length) {
        exposeKeys.forEach(key => {
          needExposeFilterInfo[key] = filterInfoWithNodeRes[key]
        })
      }

      const resultData = {
        ...(data.nodeRes || {}),
        bffFilterInfo: data.bffFilterInfo || {},
        bffProductsInfo: bffGoodsResult.code === '0' ?  { code: '0', ...bffGoodsResult.info } : {},
        bffCccFeedbackInfo: bffCccFeedbackResult.code === '0' ? bffCccFeedbackResult.info : {},
        isAdultRankSwitchBff: isSwitchBffApiVersion(['adult_rank_v1'])?.adult_rank_v1,
        cccCommonComponentResult,
        bffTabsInfo: bffTabs.code === '0' ? bffTabs.info : {},
        // _filterRenderHandle: data._filterRenderHandle,
        bffSearchBannerCardInfo: bffSearchBannerCardResult.code === '0' ? bffSearchBannerCardResult.info : {},
        ...needExposeFilterInfo,
        isFilterHandler: data?._filterRenderHandle?.isFilterHandler,
      }

      // sheinPick页面导航数据
      if (data.sheinPicksPageConfig) {
        resultData.picksMenuList = data.sheinPicksPageConfig?.picksMenuList || []
        resultData.bgBanner = data.sheinPicksPageConfig?.bgBanner || {}
      }

      if (isUserBehavior) {
        // eslint-disable-next-line no-unused-vars
        let { products, ...res } = resultData.bffProductsInfo
        Object.assign(resultData, {
          ...res
        })
      }

      if (data.picksMenuList?.length) {
        resultData.picksMenuList = data.picksMenuList
      }

      handleGoodsImgLessenIndex(resultData)
      return resultData
    } else {
      const result = await nodeRequest({
        withoutAbort: requestInfo.query?.withoutAbort,
        toRouteForward: toRoute,
      })
      return result
    }
  } catch (e) {
    console.error(e)
    // 用于点后刷判断错误类型
    if (isPicked) {
      return { error: e }
    }
    return {}
  }
}

const getNodeRequest = (requestInfo, newSrcTabPageId, toRoute) => {
  const params = generateParams(requestInfo, newSrcTabPageId, toRoute)
  // toRouteForward 用于处理无 Route实例的情况，例如：实时反馈半屏场景(注意栈溢出报错问题！！！)
  return ({ useBffProducts, useBffFilters, useBffSearchBannerCard,  withoutAbort, toRouteForward } = {}) => apiCache.request({ ...params, useBffProducts, useBffFilters, useBffSearchBannerCard, withoutAbort }, { toRouteForward })
}

/**
 * bff 请求队列
*/

// 图文导航
const requestPicNav = (requestInfo, picNavParams) => {
  const hidePicNav = requestInfo.query.is_hide_image_nav === '1'
  if (hidePicNav) {
    return new Promise(resolve => {
      resolve({})
    })
  }

  return selectionAndEntityPicNavApiCache.request(picNavParams)
}

const matchPageType = (toRoute) => {
  const pageType = {
    isSearch: false,
    isSelect: false,
    isReal: false,
    isPicked: false,
    isUserBehavior: false,
    isFeedBackRec: false,
    isDiscount: false,
    isDailyNew: false,
    isSheinPicks: false,
    isRealHalf: false
  }
  if (!toRoute?.path) {
    return pageType
  }
  // @tips 尽量减少正则表达式的匹配次数
  if (toRoute.path.match(ROUTE_REGEX.real) && toRoute.drawerType === 'entityDrawer') {
    // 类目反馈半屏
    pageType.isRealHalf = true
  } else if (toRoute.path.match(ROUTE_REGEX.search)) {
    pageType.isSearch = true
  } else if (toRoute.path.match(ROUTE_REGEX.select)) {
    pageType.isSelect = true
  } else if (toRoute.path.match(ROUTE_REGEX.real)) {
    pageType.isReal = true
  } else if (toRoute.path.match(ROUTE_REGEX.picked)) {
    pageType.isPicked = true
  } else if (toRoute.path.match(ROUTE_REGEX.pickedProducts)) {
    pageType.isUserBehavior = true
  } else if (toRoute.path.match(ROUTE_REGEX.feedbackRec) || toRoute.drawerType === 'feedbackRec') {
    //兼容实时反馈半屏
    pageType.isFeedBackRec = true
  } else if (toRoute.name === 'discount-channel-list') {
    pageType.isDiscount = true
  } else if (toRoute.path.match(ROUTE_REGEX.daily_new)) {
    pageType.isDailyNew = true
  } else if (toRoute.path.match(ROUTE_REGEX.sheinPicks)) {
    pageType.isSheinPicks = true
  } else if (toRoute.path.match(ROUTE_REGEX.selling)) {
    pageType.isSelling = true
  }

  return pageType
}

const getBffRequestQueue = async (requestInfo, toRoute, newSrcTabPageId) => {
  const SEARCH_V1 = 'search_v1'
  const PICKED_V1 = 'picked_v1'
  const SELECTION_V1 = 'selection_v1'
  const DISCOUNT_CHANNEL_V1 = 'discount_channel_v1'

  const FEEDBACK_REC_V1 = 'feedback_rec_v1'
  const SEARCH_BANNER_CARD_V1 = 'search_banner_card_v1'
  const bffSwitchVersion = isSwitchBffApiVersion([
    SEARCH_V1, PICKED_V1, SELECTION_V1, 'adult_rank_v1', DISCOUNT_CHANNEL_V1,
    FEEDBACK_REC_V1, SEARCH_BANNER_CARD_V1
  ])
  const isSearchSwitchBff = bffSwitchVersion?.[SEARCH_V1]
  const isPickedSwitchBff = bffSwitchVersion?.[PICKED_V1]
  const isSelectSwitchBff = bffSwitchVersion?.[SELECTION_V1]
  const isSellingSwitchBff = bffSwitchVersion?.[DISCOUNT_CHANNEL_V1]
  const isFeedbackRecSwitchBff = bffSwitchVersion?.[FEEDBACK_REC_V1] || true
  const isAdultRankSwitchBff = bffSwitchVersion?.adult_rank_v1
  const isSearchBannerCardSwitchBff = bffSwitchVersion?.[SEARCH_BANNER_CARD_V1]
  // !BFF 场景，入口依赖列表的是否走 BFF，因此判断改为根据 url 的 scene 参数来判断
  // const isDiscountSwitchBff = bffSwitchVersion?.discount_channel_v1
  const isDiscountSwitchBff = requestInfo?.query?.scene

  const {
    isSelling,
    isSearch,
    isSelect,
    isReal,
    isPicked,
    isUserBehavior,
    isFeedBackRec,
    isDiscount,
    isDailyNew,
    isSheinPicks,
    isRealHalf,
  } = matchPageType(toRoute)

  if (isSearch && isSearchSwitchBff) {
    // 搜索结果页
    const { goodsParams, filterParams, bannerCardParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
    const reqObj = {
      goods: goodsApiCache.request({
        type: 'search',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }),
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const res = await searchFilterApiCache.request({ type: 'search', params: filterParams }) // 图文导航，分类/属性/tsp筛选，标签云
        cb(null, res)
      },
    }
    if (requestInfo.type !== 'nextpage' && isSearchBannerCardSwitchBff) {
      reqObj.searchBannerCard = searchBannerCardApiCache.request({
        params: bannerCardParams
      }) // 搜索页顶部卡
    }
    return reqObj
  } else if (isSelect && isSelectSwitchBff) {
    // 选品分类列表页
    const { goodsParams, filterParams, picNavParams, cloudTagParams, categoryDayTagParams, cccCommonComponentParams } = getSelectGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'selection',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        filterParams._requestType = 'selection'
        filterParams._type = 'selection'
        picNavParams._requestType = requestInfo.type  // 传入，里面判断删除
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffPicNavResult: requestPicNav(requestInfo, picNavParams), // 图文导航
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
          bffDailyDatesResult: categoryDayTagApiCache.request(categoryDayTagParams) // 标签云中的date标签数据  没有show_daily不会请求
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      },
      cccCommonComponentApiData: async (cb) => {
        if (!isAdultRankSwitchBff) {
          return cb(null, null)
        }
        if (requestInfo.type === 'nextpage') return cb(null, null)
        const result = await cccCommonComponentApiCache.request(cccCommonComponentParams)
        cb(null, result)
      },
    }
    return queueObj
  } else if (isReal && isSelectSwitchBff) {
    // 真实分类列表页
    const { goodsParams, filterParams, picNavParams, cloudTagParams, cccCommonComponentParams } = getRealGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'entity',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        picNavParams._requestType = requestInfo.type   // 传入，里面判断删除
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffPicNavResult: requestPicNav(requestInfo, picNavParams), // 图文导航
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      },
      cccCommonComponentApiData: async (cb) => {
        if (!isAdultRankSwitchBff) {
          return cb(null, null)
        }
        if (requestInfo.type === 'nextpage') return cb(null, null)
        const result = await cccCommonComponentApiCache.request(cccCommonComponentParams)
        cb(null, result)
      },
    }
    return queueObj
  } else if (isSelling && isSellingSwitchBff) {
    // 卖点列表页
    const { goodsParams, filterParams, cloudTagParams } = getSellingGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'selling',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})

        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      }
    }
    return queueObj
  } else if (isSheinPicks && isSellingSwitchBff) {
    // shein picks页
    const { navParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)

    let bgBanner = {}
    let picksMenuList = []
    if (requestInfo.type === 'firstload') {
      const navTabRes = await sheinPicksNavTabApiCache.request({
        params: navParams
      })

      bgBanner = navTabRes.info?.background || {}

      if (navTabRes.code === '0' && navTabRes.info?.categories?.length > 0) {
        picksMenuList = navTabRes.info.categories.map((item) => {
          return {
            adp: item.adp,
            id: item.cat_id,
            title: item.cat_name,
            image: item.image,
            hrefTarget: item.cat_id,
            hrefType: Number(item.type) === 1 ? 'realOne' : 'itemPicking',
            catChecked: true,
          }
        })

        const firstItem = picksMenuList[0]
        if (firstItem.hrefType === 'realOne') {
          requestInfo.query.sub_type = 'entity'
          requestInfo.query.cat_id = firstItem.id
          requestInfo.query.entity_id = firstItem.id
        } else {
          requestInfo.query.sub_type = 'selection'
          requestInfo.query.select_id = firstItem.id
        }

        requestInfo.query.adp = firstItem.adp
        requestInfo.query.hrefTarget = navTabRes.info?.pageId
        requestInfo.query.navId = firstItem.id
        requestInfo.query.picks_id = firstItem.id
      }
    }

    // shein picks页
    const { goodsParams, filterParams, cloudTagParams } = getSheinPicksGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'sheinPicks',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      },
      sheinPicksPageConfig: (cb) => {
        cb(null, picksMenuList.length > 0 ? {
          picksMenuList,
          bgBanner
        } : {})
      }
    }
    return queueObj
  } else if (isPicked && isPickedSwitchBff) {
    // 信息流落地页
    const { goodsParams, filterParams, cloudTagParams } = getPickedGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'picked',
        params: goodsParams,
        addUserBehaviorParams:
          requestInfo.query?.channelIndex === '0' &&
          ![12, 13].includes(+requestInfo.query?.carrierSubType) &&
          requestInfo.query?.addUserBehaviorParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const result = await promiseAsyncAuto({
          bffCateAttrResult: pickedFilterApiCache.request({ params: filterParams }), // 分类/属性/tsp筛选
          bffCloudTagResult: pickedCloudTagApiCache.request({ params: cloudTagParams }), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      }
    }
    return queueObj
  } else if (isUserBehavior) {
    const params1 = generateParams(requestInfo, newSrcTabPageId)
    const params = {
      page: params1?.page || 1,
      size: +params1?.size || 20,
      carrierSubType: params1?.carrierSubType || '',
      contentCarrierId: params1?.contentCarrierId || '',
      entranceGoodsId: params1?.entranceGoodsId || '',
    }
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'userBehavior',
        params: params
      }),
    }
    return queueObj
  } else if (isFeedBackRec && isFeedbackRecSwitchBff) {
    const { goodsParams, filterParams, cloudTagParams } = getFeedbackRecGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'feedbackRec',
        params: goodsParams,
        method: 'GET',
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const result = await promiseAsyncAuto({
          bffCateAttrResult: feedbackRecFilterApiCache.request({ params: filterParams }), // 分类/属性/tsp筛选
          bffCloudTagResult: feedbackRecCloudTagApiCache.request({ params: cloudTagParams }), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      }
    }
    return queueObj
  } else if (isDiscount && isDiscountSwitchBff) {
    const {
      goodsParams,
      tabsParams
    } = getDiscountGenerateBffParams(requestInfo)

    return {
      goods: goodsApiCache.request({
        url: DISCOUNT.goods,
        params: goodsParams,
        method: 'GET',
        eventName: 'api_request_discount_goods',
      }),
      tabs: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, null)
        const result = await discountPageInfoApiCache.request({
          url: DISCOUNT.tabs,
          params: tabsParams,
          method: 'GET',
          eventName: 'api_request_discount_tabs',
        })
        cb(null, result)
      },
    }
  } else if (isDailyNew && isSellingSwitchBff) {
    const { goodsParams, filterParams, cloudTagParams, categoryDayTagParams } = getDailyNewGenerateBffParams(requestInfo, newSrcTabPageId)
    const queueObj = {
      goods: goodsApiCache.request({
        url: PRODUCT_LIST_REQUEST_URL_MAP.DAILY_NEW.goods,
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }),
      // cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})

        const result = await promiseAsyncAuto({
          bffCateAttrResult: dailyNewFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffCloudTagResult: dailyNewCloudTagApiCache.request(cloudTagParams), // 标签云
          bffDailyDatesResult: showRecentDaysTagApiCache.request(categoryDayTagParams) // 标签云中的date标签数据
        })
        cb(null, generateRealSelectBffFilterInfo(result))
      },
    }
    return queueObj
  } else if (isRealHalf && isFeedbackRecSwitchBff) {
    // 类目推荐半屏(就是不完全的真实分类页， 单拆出来就是怕污染真实分类页的逻辑), 以后再有半屏都可以在这扩展
    const { goodsParams, filterParams, cloudTagParams } = getRealHalfGenerateBffParams(requestInfo, newSrcTabPageId, toRoute)
    const queueObj = {
      goods: goodsApiCache.request({
        type: 'entity',
        params: goodsParams,
        withoutAbort: requestInfo.query?.withoutAbort,
      }), // 商品数据
      cccFeedbackData: cccFeedbackApiCache.request({ v: '1.0' }), // 反馈弹窗
      filterData: async (cb) => {
        if (requestInfo.type === 'nextpage') return cb(null, {})
        const result = await promiseAsyncAuto({
          bffCateAttrResult: selectionAndEntityFilterApiCache.request(filterParams), // 分类/属性/tsp筛选
          bffCloudTagResult: selectionAndEntityCloudTagApiCache.request(cloudTagParams), // 标签云
        })
        cb(null, generateRealSelectBffFilterInfo(result, requestInfo.query))
      },
    }
    return queueObj
  }
  return {}
}


// 优化策略商品图先上屏
function handleGoodsImgLessenIndex(data) {
  const isPicTopNav = data?.picTopNav?.length || data.bffFilterInfo?.navs?.length
  if (data?.listAbtResult?.PerformanceLogNodeTestFetch?.p?.PerformanceLogNodeTestFetch !== 'YES' || !isPicTopNav) {
    return
  }
  const isIos = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
  if (isIos) return
  // 开启商品图片上屏优化
  // 判断图文，标签云，图文导航是否有数据，有数据则开启图片上屏优化
  data.imgItemPrefromanceIndex = 4
}

/**
 * 检测是否数据已经缓存
*/
const getProductListApiCache = (requestInfo, newSrcTabPageId, toRoute) => {
  const isSearchSwitchBff = isSwitchBffApiVersion(['search_v1'])?.search_v1
  const isSearch = toRoute?.path?.match(ROUTE_REGEX.search)
  if (isSearch && isSearchSwitchBff) {
    const { goodsParams } = getSearchGenerateBffParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(goodsParams)
  } else {
    const params = generateParams(requestInfo, newSrcTabPageId)
    return apiCache.getCacheData(params)
  }
}

let prehtmlFlag = false // 单页水合时，不发起预请求
function changePreHtmlUpdate(value) {
  prehtmlFlag = value
}

/**
 * 路由的预请求
 * */
function handleProductListPreRequestBeforeEachRoute(to, from) {
  console.log('路由的预请求', from)
  if (prehtmlFlag) return // 单页水合时，不发起预请求
  const isProductListRoute = PRODUCT_LIST_ROUTE_NAME.includes(to.name)
  if (!isProductListRoute) return

  if (from.name === 'preload-request') {
    // 点击pointer触发的预请求， 由于快于beforeEach，所以在这里触发进行校验一次
    userTracekPreCheckEnd({ featureKey: 'click_refresh', to })
  }

  if (to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterStartTrigger', measureTo: 'RouterEndTrigger' })
  }

  setCurrentToRoute(to)
  const isSpaRouteChange = (fromRoute) => Boolean(fromRoute.name)
  const isSameRouteChange = (to, from) => to.name === from.name
  if (isSpaRouteChange(from)) {
    if (!isSameRouteChange(to, from)) {
      requestProductListApi({ requestInfo: defaultRequestInfo(to.query), newSrcTabPageId: undefined, toRoute: to })
    }
  }
}

// to, from
function handleProductListPreRequestAfterEachRoute(to) {
  if (!prehtmlFlag && to?.name === 'product-list-v2') {
    markPoint({ eventName: 'RouterEndTrigger', measureTo: 'PageWaitInit' })
  }
}

if (typeof window !== 'undefined') {
  window.apiCacheProducts = apiCache
}

const abortRequest = (abortReason) => {
  showRecentDaysTagAbortionInstance?.abort?.(abortReason)
  dailyNewFilterAbortionInstance?.abort?.(abortReason)
  dailyNewCloudTagAbortionInstance?.abort?.(abortReason)
  cccFeedbackAbortionInstance?.abort?.(abortReason)
  abortionInstance?.abort?.(abortReason)
  goodsAbortionInstance?.abort?.(abortReason)
  hypernymGoodsAbortionInstance?.abort?.(abortReason)
  searchFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityFilterAbortionInstance?.abort?.(abortReason)
  selectionAndEntityPicNavAbortionInstance?.abort?.(abortReason)
  selectionAndEntityCloudTagAbortionInstance?.abort?.(abortReason)
  pickedFilterAbortionInstance?.abort?.(abortReason)
  pickedCloudTagAbortionInstance?.abort?.(abortReason)
  sheinPicksNavTabAbortionInstance?.abort?.(abortReason)
  feedbackRecFilterAbortionInstance?.abort?.(abortReason)
  feedbackRecCloudTagAbortionInstance?.abort?.(abortReason)
  searchBannerCardAbortionInstance?.abort?.(abortReason)
  discountPageInfoApiCache?.abort?.(abortReason)
}

/**
 * 触摸提前预请求方法。让请求提前触发
 * */
const pointerOverGoodsRequest = new PointerOverGoodsRequest({
  fetch: (path) => {
    const toRouteParams = generateRouterParams(path)
    if (!toRouteParams) return
    handleProductListPreRequestBeforeEachRoute(toRouteParams, { name: 'preload-request' })
  },
  cancelToken: () => abortRequest(),
})

// 做kids筛选时候，清除对应接口缓存
const clearApiCacheInKidsFilter = () => { 
  clearNodeApiCache()
  clearGoodsApiCache()
  clearHypernymGoodsApiCache()
  clearSearchFilterApiCache()
  clearSearchBannerCardApiCache()
  clearSelectionAndEntityFilterApiCache()
  clearSelectionAndEntityCloudTagApiCache()
  clearSheinPicksNavTabApiCache()
}

export {
  abortionInstance,
  abortRequest,
  requestProductListApi,
  setCurrentToRoute,
  handleProductListPreRequestBeforeEachRoute,
  handleProductListPreRequestAfterEachRoute,
  pointerOverGoodsRequest,
  changePreHtmlUpdate,
  getProductListApiCache,
  clearApiCache,
  clearApiCacheInKidsFilter
}
