import { getViewedGoods, getVuexInfo, getExposedGoodsIdWithSpu } from './common.js'
import { getExposedGoods as getCheckedExposedGoods } from 'public/src/pages/common/refresh_list_strategy/helpers/index.js'

// 点刷需求的关键入参
const getRefreshListStrategyParams = (requestInfo, toRoute, diffPageType) => {

  const { goods, stateResults, listAbtResult } = getVuexInfo(toRoute)

  let frontFilterGoods = stateResults.frontFilterGoods
  // const exposedGoodsWithSpu = stateResults.exposedGoodsWithSpu || []

  // 修正：因为observe不准问题
  const filteredGoods = goods.filter(item => item.divideTime !== 'recommend')

  const { exposedProducts = [] } = getCheckedExposedGoods({
    exposedProducts: stateResults.exposedGoods, goods: filteredGoods
  })

  const exposedGoodsWithSpu = exposedProducts.map(item => `${item.goods_id}-${item.productRelationID}`)

  const isClickRefresh = requestInfo.query.isClickRefresh
  const divideTime = requestInfo.query.divideTime

  const ListDivideTime =  listAbtResult?.ListDivideTime?.p?.ListDivideTime || window?._abt_server_provider?.abtMap?.get('ListDivideTime')?.p?.ListDivideTime
  const PageDivideTime = listAbtResult?.PageDivideTime?.p?.page_divide_time || window?._abt_server_provider?.abtMap?.get('PageDivideTime')?.p?.page_divide_time

  const listDivideTimeAbt = ['request_early', 'request_early_back'].includes(ListDivideTime) ? 1 : 0 
  const searchDivideTimeAbt = ['request_early', 'request_early_back'].includes(PageDivideTime) ? 1 : 0

  let getLoadedGoods = getViewedGoods(toRoute, requestInfo, goods)

  const getExposedGoods = getExposedGoodsIdWithSpu(toRoute, requestInfo, exposedGoodsWithSpu)
  const filter_goods_infos = isClickRefresh ? getExposedGoods : getLoadedGoods 

  // const isOverMaxViewedGoods = requestInfo.type === 'nextpage' && getLoadedGoods === ''  
  const loadViewedGoodsLen = goods.filter(item => item.divideTime !== 'recommend').length

  let loadedGoodsPosition = 0

  if (requestInfo.type === 'firstload' || requestInfo.type === 'refresh') {
    loadedGoodsPosition = 0
    frontFilterGoods = ''
  } else {
    loadedGoodsPosition = loadViewedGoodsLen
  }

  //  else if (isOverMaxViewedGoods) {
  //     loadedGoodsPosition = overMaxViewedGoods
  //   } 

  const exposedPosition = isClickRefresh ? exposedGoodsWithSpu.length : loadedGoodsPosition

  let postClickRefresh = 0

  if (['realListPage', 'selectListPage', 'sheinPicksListPage'].includes(diffPageType)) {
    postClickRefresh = listDivideTimeAbt
  } else if (['searchListPage'].includes(diffPageType)) {
    postClickRefresh = searchDivideTimeAbt
  }

  // console.log(`%c 当前 exposedPosition 为: ${exposedPosition} `, 'background: pink; color: blue')


  const notCache = isClickRefresh ? {
    timestamp: Date.now().toString() // 时间戳避免被缓存
  } : {}

  return {
    frontFilterGoods,
    filter_goods_infos,
    postClickRefresh, // 当前场景是否开启点刷
    isClickRefresh, // 当前请求是否是点后刷请求
    exposedPosition, // 当前列表实际曝光坑位数
    usePage: 1, // 点刷需求特殊标识，让客户端自行完成 page 修正
    divideTime, // 调试使用
    ...notCache,
  }
}

export {
  getRefreshListStrategyParams
}
