import { getCommonParams } from './common.js'
import { cleanNullValueObjectFirstLevel } from './utils.js'

// 商品接口入参
const getGoodsParams = requestInfo => {
  const query = requestInfo.query || {}
  const {
    quickship,
    filter,
    maxPrice,
    minPrice,
  } = getCommonParams(query)
  /**
   * 会员态怎么办？TODO:
   * reqSheinClub: atomicParams.reqSheinClub,
   * isPaid: atomicParams.isPaid,
   */

  let params = {
    adp: query.adp || '',
    filter,
    max_price: maxPrice,
    min_price: minPrice,
    quickship,
    sort: query.sort || '',
    page: query.page || 1,
    limit: query.limit || 10,
    filter_goods_similar: query.filterGoodsSimilar,	
    filter_goods_yaml: query.filterGoodsYaml,
    goods_cate_id: query.catId,
    goods_discount: query.discountRate,
    goods_id: query.goodsId,
    goods_price: query.goodsPrice,
    triggerEvent: query.triggerEvent,
    // add_cart
    // favorite
    // filter_cate_id
    // main_cate_id
    // main_select_id
    // scene todo 确认
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 筛选接口入参（属性，分类，tsp筛选）
const getFilterParams = requestInfo => {
  const query = requestInfo.query || {}
  const {
    quickship,
    filter,
    maxPrice,
    minPrice,
  } = getCommonParams(query)

  let params = {
    choosed_ids: query.choose_ids,
    filter,
    filter_goods_similar: query.filterGoodsSimilar,	
    filter_goods_yaml: query.filterGoodsYaml,
    goods_cate_id: query.catId,
    goods_discount: query.discountRate,
    goods_id: query.goodsId,
    goods_price: query.goodsPrice,
    last_parent_cat_id: query.last_parent_cat_id,
    max_price: maxPrice,
    min_price: minPrice,
    quickship,
    selectAttributeGroup: query.selectAttributeGroup || '', // 选中的属性组合
    triggerEvent: query.triggerEvent,
    // add_cart
    // favorite
    // filter_cate_id
    // main_cate_id
    // main_select_id
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

// 标签云相关
const getCloudTagParams = requestInfo => {
  const query = requestInfo.query || {}
  const {
    filter,
    quickship,
    maxPrice,
    minPrice,
  } = getCommonParams(query)

  let params = {
    filter,
    filter_goods_similar: query.filterGoodsSimilar,	
    filter_goods_yaml: query.filterGoodsYaml,
    goods_cate_id: query.catId,
    goods_discount: query.discountRate,
    goods_id: query.goodsId,
    goods_price: query.goodsPrice,
    max_price: maxPrice,
    min_price: minPrice,
    quickship,
    sort: query.sort || '',
    triggerEvent: query.triggerEvent,
    // add_cart
    // favorite
    // filter_cate_id
    // main_cate_id
    // main_select_id
  }

  // 抹掉空参
  params = cleanNullValueObjectFirstLevel(params)

  return params
}

/**
 *
 * @param {Object} requestInfo
 * @param {String} requestInfo.type firstload 首次进入列表, nextpage 翻页, refresh 筛选
 * @param {Object} requestInfo.query
 * @param {String} requestInfo.query.page 页码
 * @param {String} requestInfo.query.limit 商品数
 * @param {String} requestInfo.query.search_type 搜索子类型 store 门店搜索, brand 专题搜索
 * @param {String} requestInfo.query.xxx 剩下一些路由上带的参数
 * @param {*} newSrcTabPageId 埋点字段
 * @returns
 */
export const getFeedbackRecGenerateBffParams = (requestInfo, newSrcTabPageId) => {
  return {
    goodsParams: getGoodsParams(requestInfo, newSrcTabPageId),
    cloudTagParams: getCloudTagParams(requestInfo),
    filterParams: getFilterParams(requestInfo),
  }
}
